import React from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { lang } from '@common-sense-privacy/common'

import QuestionsContactSupport from '@/components/QuestionsContactSupport'

import { useAlert } from '@/context/AlertContext'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import {
  useCurrentOrganization,
  usePlanType,
} from '@/hooks/useSession'

import { usePostProductSnapshotMutation } from '@/services/organization/product/snapshot'

import { isStarterPlan } from '@/utils/stripe'

import CarefullyRead from '../../assets/images/carefullyRead.svg'
import Topics from '../../assets/images/topics.svg'
import AnswerAll from '../../assets/images/answerAll.svg'

function FinalizeSubmit(): React.ReactElement {
  const navigate = useNavigate()
  const { setAlert } = useAlert()
  const { id: organizationId } = useCurrentOrganization()
  const { productId } = useRouteParams()
  const planType = usePlanType()

  const [
    postProductSnapshot,
    { isLoading },
  ] = usePostProductSnapshotMutation()

  const handleOnSubmit = async () => {
    if (!productId) {
      setAlert({
        description: lang().messages.unknownError(),
        type: 'error',
      })

      return
    }

    if (planType && isStarterPlan(planType)) {
      navigate('/subscriptions/plans?finalize=true', { state: { requiresPaidPlan: true } })

      return
    }

    await postProductSnapshot({
      params: {
        id: organizationId,
        productId,
      },
    })
      .unwrap()
      .then(() => {
        setAlert({
          description: 'Congratulations! Your privacy policy is being generated.',
          type: 'success',
        })

        navigate('/privacy-policies')
      })
      .catch(() => {
        setAlert({
          description: lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }

  return (
    <>
      <Container>
        <Stack spacing={4} maxWidth='sm'>
          <Button
            onClick={() => navigate(-1)}
            variant='link'
            data-testid='privacy-finalize-back'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: 'text.primary',
              textDecoration: 'none',
              fontWeight: 'bold',
              left: '-8px',
            }}
          >
            <KeyboardArrowLeftIcon /> Back
          </Button>
          <Typography variant='h1' mt={4}>FINALIZE & SUBMIT</Typography>
          <Typography>
            You have evaluated all of the questions in the Common Sense Privacy Wizard.
            Once you finalize your submission we’ll generate your policy. Please take a moment to confirm that you are satisfied with your answers.
          </Typography>
          <Typography fontWeight='bold'>Did you...</Typography>
          <Stack spacing={2}>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Box
                component='img'
                sx={{
                  height: 35,
                  width: 35,
                }}
                alt='Answer All'
                src={Topics}
              />
              <Typography fontSize={17}>Carefully read and answer each question?</Typography>
            </Stack>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Box
                component='img'
                sx={{
                  height: 35,
                  width: 35,
                }}
                alt='Answer All'
                src={CarefullyRead}
              />
              <Typography fontSize={17}>Save questions for follow up if you were unsure?</Typography>
            </Stack>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Box
                component='img'
                sx={{
                  height: 35,
                  width: 35,
                }}
                alt='Answer All'
                src={AnswerAll}
              />
              <Typography fontSize={17}>Answer as many questions as possible?</Typography>
            </Stack>
          </Stack>
          <Typography fontWeight='bold'>When you’re ready, please click below to finalize your submission.</Typography>
          <Box>
            <Button disabled={isLoading} onClick={handleOnSubmit}>Finalize & Submit Entries</Button>
          </Box>
        </Stack>
      </Container>
      <Container>
        <Stack mt={8} spacing={4}>
          <Divider />
          <QuestionsContactSupport />
        </Stack>
      </Container>
    </>
  )
}

export default React.memo(FinalizeSubmit)
