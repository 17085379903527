import React, { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  MenuItem,
  Stack,
  Typography,
  Grid,
} from '@mui/material'

import {
  api,
  url,
} from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import useNavigate from '@/hooks/useNavigate'

import { usePostSignUpMutation } from '@/services/signup'
import { setSession } from '@/state/slices/session'
import { useGetRolesQuery } from '@/services/roles'

import { useSignup } from '@/context/SignupContext'

import StateField from '@/components/Account/OrganizationState'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Role,
} from './types'

function SignUp(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const {
    signupValues,
    setSignupValues,
  } = useSignup()

  const {
    data: roles,
    isLoading: isRolesLoading,
  } = useGetRolesQuery()

  const [
    postSignUp,
    { isLoading },
  ] = usePostSignUpMutation()

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    setSignupValues(values)
    postSignUp({ ...values })
      .unwrap().then(response => {
        dispatch(setSession(response.data))
        navigate('/signup/verify')
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    setSignupValues,
    postSignUp,
    navigate,
    dispatch,
    handleFormApiErrors,
  ])

  const { rules } = api.endpoints.signup.validation.post.body
  const formRules = { ...rules }
  const organizationSizes = api.endpoints.signup.validation.ORGANIZATION_SIZES
  const { COUNTRY_LIST: countryList } = api.endpoints.organization.validation

  return (
    <DocumentWrapper title='Common Sense Privacy | Sign Up'>
      <ScreenWrapper>
        <Container maxWidth='md'>
          <Typography variant='h5' pb='100px' textAlign='center'>To get started, create an account for your organization.</Typography>
          <Divider />
        </Container>
        <Container maxWidth='md'>
          <Form<FormValues> initialValues={signupValues} onSubmit={handleSubmit} rules={formRules}>
            <Grid container={true} pt='50px' spacing={4} justifyContent='space-between' mb={3}>
              <Grid item={true}>
                <Typography variant='body2' textAlign='left'>Account Information</Typography>
              </Grid>
              <Grid item={true} sm={8} md={8}>
                <Stack maxWidth='sm' marginTop={3} spacing={4}>
                  {/* Commented out for v1.0 */}
                  {/* <Typography variant='intro' textAlign='center'>Complete questions for free. Pay to download your policy.</Typography> */}
                  <FormField
                    name='firstName'
                    label='First Name *'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-first-name' }}
                  />
                  <FormField
                    name='lastName'
                    label='Last Name *'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-last-name' }}
                  />
                  <FormField
                    name='jobTitle'
                    label='Job Title'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-job-title' }}
                  />
                  <FormField
                    type='select'
                    name='roles'
                    labelId='demo-simple-select-label'
                    id='demo-multiple-name'
                    label='Functional Roles'
                    data-testid='signup-functional-roles-wrapper'
                    inputProps={{ 'data-testid': 'signup-functional-roles' }}
                    multiple={true}
                  >
                    {(roles?.data || []).map((role: Role) => (
                      <MenuItem
                        key={role.id}
                        value={role.id}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </FormField>

                  <FormField
                    name='email'
                    label='Email *'
                    variant='outlined'
                    inputProps={{ 'data-testid': 'signup-email' }}
                    fieldHelperText={'This should be your professional or personal email address. '
                    + 'We will use this email address to contact you about your Common Sense Privacy account. '
                    + 'This email will not be exposed in your privacy policy.'}
                  />
                  <FormField
                    name='password'
                    type='password'
                    label='Password *'
                    variant='outlined'
                    inputProps={{ 'data-testid': 'signup-password' }}
                    fieldHelperText='Include a minimum of 8 characters made up of numbers, letters, and a special character.'
                  />

                  <FormField
                    name='confirmPassword'
                    type='password'
                    label='Confirm Password *'
                    variant='outlined'
                    inputProps={{ 'data-testid': 'signup-confirm-password' }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <Grid container={true} pt='50px' spacing={4} justifyContent='space-between' mb={3}>
              <Grid item={true}>
                <Typography variant='body2' textAlign='left'>Organization Information</Typography>
              </Grid>
              <Grid item={true} sm={8} md={8}>
                <Stack maxWidth='sm' marginTop={3} spacing={4}>
                  <FormField
                    name='organizationName'
                    label='Organization Name *'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-organization-name' }}
                  />
                  <FormField
                    type='select'
                    name='organizationSize'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Organization Size *'
                    inputProps={{ 'data-testid': 'signup-organization-size' }}
                  >
                    {organizationSizes.map((size: string) => (
                      <MenuItem key={size} value={size}>{size}</MenuItem>
                    ))}
                  </FormField>
                  <FormField
                    name='organizationEmail'
                    label='Organization Email *'
                    variant='outlined'
                    inputProps={{ 'data-testid': 'signup-organization-email' }}
                    fieldHelperText={'Organization email will appear in the privacy policy. '
                    + 'Users of your product will use this to contact your organization with privacy related questions.'}
                  />
                  <FormField
                    type='select'
                    name='organizationCountry'
                    labelId='orgnization-country-select-label'
                    id='organization-country-select'
                    label='Country/State of Organziation *'
                    inputProps={{ 'data-testid': 'signup-organization-country' }}
                  >
                    {countryList.map(item => (
                      <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                    ))}
                  </FormField>
                  <FormField
                    name='streetAddress'
                    label='Street Address *'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-street-address' }}
                  />
                  <FormField
                    name='city'
                    label='City *'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-city' }}
                  />
                  <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                    <StateField />
                    <FormField
                      name='zipCode'
                      label='Zip Code *'
                      variant='outlined'
                      type='text'
                      inputProps={{ 'data-testid': 'signup-zip-code' }}
                      style={{ width: '100%' }}
                    />
                  </Stack>
                  <FormField
                    name='poBox'
                    label='PO Box'
                    variant='outlined'
                    type='text'
                    inputProps={{ 'data-testid': 'signup-po-box' }}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Divider />
            <Grid
              container={true}
              pt='50px'
              spacing={4}
              justifyContent='space-between'
              mb={3}
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
                xl: 'row',
              }}
            >
              <Grid item={true}>
                <Typography variant='body2' textAlign='left'>Product Information</Typography>
              </Grid>
              <Grid item={true} md={8}>
                <Stack maxWidth='sm' marginTop={3} spacing={4}>
                  <FormField
                    type='select'
                    name='hasProductPolicies'
                    labelId='orgnization-has-product-policies-select-label'
                    id='organization-has-product-policies-select'
                    label='Do you have an existing privacy policy? *'
                    inputProps={{ 'data-testid': 'signup-organization-has-product-policies' }}
                  >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </FormField>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <FormField
              name='agreeToTerms'
              type='checkbox'
              label={(
                <Typography variant='body2'>
                  I have read and agree to the{' '}
                  <Link href={url.marketingSite('/terms-of-service')} target='_blank' color='inherit'>
                    Terms{' '}
                  </Link>
                  &{' '}
                  <Link href={url.marketingSite('/privacy-policy')} target='_blank' color='inherit'>
                    Privacy Policy
                  </Link>
                </Typography>
              )}
            />
            <Box textAlign='center' mt={5}>
              <Button
                variant='contained'
                role='button'
                type='submit'
                disabled={isLoading || isRolesLoading}
              >
                Create Account
              </Button>
            </Box>
          </Form>
        </Container>
        <Container>
          <Stack spacing={4} mt={4} textAlign='center'>
            <Divider />
            <Typography variant='h6'>Already have an account?</Typography>
            <Box>
              <Button component={RouterLink} to='/signin' variant='contained'>Sign In</Button>
            </Box>
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignUp)
