import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material'

import { lang } from '@common-sense-privacy/common'

import ScreenWrapper from '@/components/ScreenWrapper'
import DocumentWrapper from '@/components/DocumentWrapper'

import { useAlert } from '@/context/AlertContext'
import { usePostSendEmailMutation } from '@/services/verify'
import { useUserEmail } from '@/hooks/useSession'

export default function AccountVerify() {
  const userEmail = useUserEmail()
  const { setAlert } = useAlert()
  const [
    postSendEmail,
    { isLoading },
  ] = usePostSendEmailMutation()

  const handleSendEmail = useCallback(() => {
    postSendEmail()
      .unwrap()
      .then(() => {
        setAlert({
          description: 'Success! Email has been sent.',
          type: 'success',
        })
      }).catch(() => {
        setAlert({
          description: lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }, [
    postSendEmail,
    setAlert,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Verify Account'>
      <ScreenWrapper>
        <Container maxWidth='sm'>
          <Stack textAlign='center' mt={2}>
            <Typography variant='h5' component='h1' marginBottom={2}>Verify your account</Typography>
            <Typography variant='intro'>Please check your email at {`${userEmail} `}
              and click the link to verify your account.  If you did not receive the
              message, request a new one below.
            </Typography>
            <Box mt={4}>
              <Button
                disabled={isLoading}
                onClick={handleSendEmail}
              >
                Send Verification Email
              </Button>
            </Box>
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}
