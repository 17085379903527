import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { lang } from '@common-sense-privacy/common'

import {
  useGetProductPrivacyIssueQuery,
  usePutProductPrivacyIssueMutation,
} from '@/services/organization/product'

import { useAlert } from '@/context/AlertContext'

import { useGetProductTopicsQuery } from '@/services/organization/product/topics'
import useRouteParams from '@/hooks/useRouteParams'
import { useCurrentOrganization } from '@/hooks/useSession'
import {
  useHasSkippedQuestion,
  setSkippedQuestion,
} from '@/hooks/useWizard'

import QuestionsContactSupport from '@/components/QuestionsContactSupport'
import CircularLoading from '@/components/CircularLoading'

import PrivacyIssueQuestion from '../../../components/PrivacyIssueQuestion'

import {
  PrivacyTopic,
  PrivacyIssue,
  ProductPrivacyIssue as ProdPrivacyIssue,
} from './types'

function ProductPrivacyIssue(): React.ReactElement {
  const dispatch = useDispatch()
  const { setAlert } = useAlert()
  const { id: organizationId } = useCurrentOrganization()
  const navigate = useNavigate()
  const {
    productId,
    topicId,
    questionId,
  } = useRouteParams()
  const hasSkippedBefore = useHasSkippedQuestion()
  const [
    skipModalOpen,
    setSkipModalOpen,
  ] = useState(false)
  const [
    jursidictionalSkippedQuestions,
    setJursidictionalSkippedQuestions,
  ] = useState(false)

  const {
    data: { data: productPrivacyIssue } = {},
    isFetching,
  } = useGetProductPrivacyIssueQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
        privacyIssueId: `${questionId}`,
      },
    },
    { refetchOnMountOrArgChange: true },
  )
  const answerSkippedQuestions = (url: string) => {
    setJursidictionalSkippedQuestions(false)
    navigate(url)
  }

  const {
    questionNumber,
    questionProgress,
    previousQuestionId,
    skippedQuestions,
    isJurisdictional,
    hasBranchTypeQuestion,
    hasAtLeastOneSkippedQuestion,
    totalNumberOfQuestionsInTopic,
  } = useGetProductTopicsQuery({
    params: {
      id: organizationId,
      productId: `${productId}`,
    },
  }, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => {
      const privacyIssue = productPrivacyIssue?.privacyIssue

      const topic = data?.data?.topics?.find((privacyTopic: PrivacyTopic) => privacyTopic.id === (privacyIssue && privacyIssue?.privacyTopic?.id))
      const hasAtLeastOneSkippedQuestion = data?.data?.topics?.some((topic: PrivacyTopic) => topic?.privacyIssues.some((topicPrivacyIssue: PrivacyIssue) => topicPrivacyIssue?.productPrivacyIssues.some((productPrivacyIssue: ProdPrivacyIssue) => productPrivacyIssue.status === 'skipped')))
      if (topic?.privacyIssues) {
        const currentQuestionIndex = topic.privacyIssues.findIndex(({ id } : {id: string}) => id === privacyIssue?.id)
        const questionNumber = currentQuestionIndex + 1
        const questionProgress = `${questionNumber} of ${topic.totalNumberOfQuestions}`
        const previousQuestion = currentQuestionIndex ? topic.privacyIssues[currentQuestionIndex - 1] : undefined
        const isJurisdictional = topic.code === '0.0.0'
        const skippedQuestions = topic.privacyIssues.filter((privacyIssue: PrivacyIssue) => privacyIssue.productPrivacyIssues.some((productPrivacyIssue: ProdPrivacyIssue) => productPrivacyIssue.status === 'skipped'))
        const totalNumberOfQuestionsInTopic = topic.totalNumberOfQuestions

        return {
          questionNumber,
          questionProgress,
          hasBranchTypeQuestion: !!topic.privacyIssues.some((topicPrivacyIssue: PrivacyIssue) => topicPrivacyIssue.children && topicPrivacyIssue.children.length > 0),
          previousQuestionId: previousQuestion?.productPrivacyIssues?.at(0)?.id,
          isJurisdictional,
          skippedQuestions,
          hasAtLeastOneSkippedQuestion,
          totalNumberOfQuestionsInTopic,
        }
      }

      return {
        questionNumber: undefined,
        questionProgress: undefined,
        previousQuestionId: undefined,
        hasAtLeastOneSkippedQuestion,
      }
    },
  })
  const [ putProductPrivacyIssueMutation ] = usePutProductPrivacyIssueMutation()

  const submitAnswers = async (answers?: (string | {name: string, url: string})[]) => {
    putProductPrivacyIssueMutation({
      body: { answers },
      params: {
        id: organizationId,
        productId: `${productId}`,
        privacyIssueId: `${questionId}`,
      },
    })
      .unwrap()
      .then(response => {
        if (response.data) {
          const {
            nextProductPrivacyIssue,
            previousProductPrivacyIssue,
          } = response.data

          if (isJurisdictional && totalNumberOfQuestionsInTopic === questionNumber) {
            const skippedQuestionsWithoutTheLastOne = skippedQuestions.filter((skippedQuestion: PrivacyIssue) => skippedQuestion.id !== previousProductPrivacyIssue?.privacyIssue.id)

            if (previousProductPrivacyIssue?.answers?.length === 0) {
              setJursidictionalSkippedQuestions(true)

              return
            }

            // we need to check if the previous question was skipped, if so we need to show the modal
            if (skippedQuestionsWithoutTheLastOne && skippedQuestionsWithoutTheLastOne.length > 0) {
              setJursidictionalSkippedQuestions(true)

              return
            }
          }

          if (nextProductPrivacyIssue?.id) {
            if (topicId !== nextProductPrivacyIssue.privacyIssue.privacyTopic.id) {
              navigate(`/wizard/products/${productId}/topics/${nextProductPrivacyIssue.privacyIssue.privacyTopic.id}`)
            }
            else {
              navigate(`/wizard/products/${productId}/topics/${nextProductPrivacyIssue.privacyIssue.privacyTopic.id}/questions/${nextProductPrivacyIssue.id}`)
            }
          }
          else {
            navigate(`/wizard/products/${productId}/finalize`)
          }
        }
      }).catch(() => {
        setAlert({
          description: lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }

  const handleOnBack = () => {
    if (previousQuestionId) {
      navigate(`/wizard/products/${productId}/topics/${topicId}/questions/${previousQuestionId}`)
    }
    else {
      navigate(`/wizard/products/${productId}/topics/${topicId}`)
    }
  }

  const handleSubmit = (answers: {vendorList: {name: string, url: string}[]} | string[]) => {
    const castedAnswers = typeof answers !== 'string' && 'vendorList' in answers ? answers.vendorList : answers as string[]
    if (!hasSkippedBefore && !hasAtLeastOneSkippedQuestion && castedAnswers.length === 0) {
      setSkipModalOpen(true)
    }
    else {
      submitAnswers(castedAnswers)
    }
  }

  const handleSkipClose = () => {
    dispatch(setSkippedQuestion(true))
    setSkipModalOpen(false)

    submitAnswers([])
  }

  if (!productPrivacyIssue) {
    return <CircularLoading />
  }

  const {
    answerInputFields,
    question,
    infoTips,
    privacyTopic: { title },
    title: subtitle,
    type,
  } = productPrivacyIssue.privacyIssue
  const [ answerInput ] = answerInputFields
  if (!answerInput) {
    return <CircularLoading />
  }

  const { id: inputId } = answerInput

  return (
    <>
      <Container>
        <Button
          onClick={handleOnBack}
          variant='link'
          data-testid='privacy-issue-back'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'text.primary',
            textDecoration: 'none',
            fontWeight: 'bold',
            left: '-8px',
          }}
        ><KeyboardArrowLeftIcon /> Back
        </Button>
        <Typography variant='h3' mt={4}>{title}</Typography>
        <Typography variant='h3' mt={0.5} color='text.secondary'>{type === 'matrix' && subtitle}</Typography>
        {!hasBranchTypeQuestion ? (<Typography variant='subtitle1' color='text.secondary'>{questionProgress}</Typography>) : undefined}
        <Stack spacing={1} direction='row' mt={4} alignItems='flex-start'>
          <Typography mb={2} color='black' variant='h6'>{questionNumber}. {question}</Typography>
          {!!infoTips && (
            <Tooltip
              placement='top'
              enterTouchDelay={0}
              PopperProps={{
                disablePortal: true,
                sx: {
                  '& .MuiTooltip-tooltip': {
                    height: '100%',
                    maxHeight: '150px',
                    overflow: 'auto',
                  },
                },
              }}
              title={<Box dangerouslySetInnerHTML={{ __html: infoTips }} />}
            ><Typography><InfoOutlinedIcon color='inherit' /></Typography>
            </Tooltip>
          )}
        </Stack>
        <Stack spacing={4} pt={3}>
          <PrivacyIssueQuestion
            inputId={inputId}
            isLoading={isFetching}
            onSave={handleSubmit}
            productPrivacyIssue={productPrivacyIssue}
            saveButtonText='Save & Continue'
          />
        </Stack>
        <Dialog
          onClose={handleSkipClose}
          open={skipModalOpen}
        >
          <DialogTitle>
            Quick note!
            <IconButton
              aria-label='close'
              onClick={handleSkipClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Typography gutterBottom={true}>
              Easily find this and other skipped questions in the list available on each topic overview page. This one will be available on the {title} overview page.
            </Typography>
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={() => {
            setJursidictionalSkippedQuestions(false)
          }}
          open={jursidictionalSkippedQuestions}
        >
          <DialogTitle>
            Oops!
            <IconButton
              aria-label='close'
              onClick={() => {
                setJursidictionalSkippedQuestions(false)
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Typography>All Jurisdictional questions must be answered for you to move on to the next section. Please answer missing questions to continue.</Typography>
            <Box mt={2}>
              {skippedQuestions && <Button onClick={() => answerSkippedQuestions(`/wizard/products/${productId}/topics/${topicId}/questions/${skippedQuestions[0].productPrivacyIssues[0].id}`)}>Answer Questions</Button>}
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
      <Container>
        <Stack mt={8} spacing={4}>
          <Divider />
          <QuestionsContactSupport />
        </Stack>
      </Container>
    </>
  )
}

export default React.memo(ProductPrivacyIssue)
